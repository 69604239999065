import * as React from 'react';
import { useSelector } from 'react-redux';
import Select, { SelectWidth } from '@kvika/select';
import { FundPurchaseRequestSchema, AppropriateFundResponseSchema } from '@kvika/api-types';
import { InputWidth } from '@kvika/input';
import { useMediaQuery } from '@kvika/hooks';
import { MediaQuery } from '@kvika/theme';
import { toIskCurrencyString } from '@kvika/string-utils';
import CurrencyInput from './CurrencyInput';
import {
  StyledContainer,
  StyledHeading,
  StyledInformationText,
  StyledFormRow,
  StyledLink,
} from './SelectFundFormStyles';
import { selectEntityState } from '../../../store/entity';

type Props = {
  orderInfoTitle: string;
  chooseAFundLabel: string;
  amountToPurchaseLabel: string;
  amountToPurchaseMinErrorText: string;
  amountToPurchaseMaxErrorText: string;
  onChange?(purchaseRequest: FundPurchaseRequestSchema): void;
  onValidationChange?: (valid: boolean) => void;
  funds: AppropriateFundResponseSchema[];
  setFundId: (id: number) => void;
  fundId?: number;
  amountToPurchaseInformation: string;
  priceListLink: string;
  isLoadingFunds?: boolean;
};

const SelectFundForm = ({
  orderInfoTitle,
  chooseAFundLabel,
  amountToPurchaseLabel,
  amountToPurchaseMinErrorText,
  amountToPurchaseMaxErrorText,
  onChange,
  onValidationChange,
  funds,
  fundId,
  amountToPurchaseInformation,
  setFundId,
  priceListLink,
  isLoadingFunds,
}: Props): JSX.Element => {
  const [amount, setAmountToPurchase] = React.useState<string>();
  const [isAmountValid, setIsAmountValid] = React.useState(false);
  const [amountFieldDirty, setAmountFieldDirty] = React.useState(false);
  const [minPurchaseAmount, setMinPurchaseAmount] = React.useState<number | undefined>(undefined);
  const [maxPurchaseAmount, setMaxPurchaseAmount] = React.useState<number | undefined>(undefined);
  const { ssn } = useSelector(selectEntityState);

  const validateAmount = (cash = amount) => {
    if (!minPurchaseAmount || !maxPurchaseAmount || !cash) {
      return false;
    }

    const parsedAmount = Number.parseInt(cash, 10);
    return parsedAmount >= minPurchaseAmount && parsedAmount <= maxPurchaseAmount;
  };
  const updateValidAmount = (cash = amount) => setIsAmountValid(validateAmount(cash));
  const isFormValid = () => {
    return !!(fundId && amount && validateAmount());
  };

  const selectedFund = funds.find((fund) => fund.id === fundId);
  React.useEffect(() => {
    setMinPurchaseAmount(selectedFund?.tradeDetails[0].minAmount);
    setMaxPurchaseAmount(selectedFund?.tradeDetails[0].maxAmount);
    updateValidAmount(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFund]);

  React.useEffect(() => {
    onValidationChange && onValidationChange(isFormValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAmountValid, fundId, amount]); // email needs to be here as well if enabled

  const getErrorText = () => {
    if (amount) {
      const parsedAmount = Number.parseInt(amount, 10);
      if (minPurchaseAmount && parsedAmount <= minPurchaseAmount) {
        return `${amountToPurchaseMinErrorText} ${toIskCurrencyString(minPurchaseAmount, true)}`;
      }
      if (maxPurchaseAmount && parsedAmount >= maxPurchaseAmount) {
        return `${amountToPurchaseMaxErrorText} ${toIskCurrencyString(maxPurchaseAmount, true)}`;
      }
    }
    return '';
  };

  React.useEffect(() => {
    const purchaseRequest = {
      fundId: fundId as number,
      amount: Number.parseInt(amount as string, 10),
      ssn,
    };
    onChange && onChange(purchaseRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundId, amount]); // email needs to be here as well if enabled
  const tabletSize = useMediaQuery(`(max-width: ${MediaQuery.Tablet})`);
  return (
    <StyledContainer>
      <StyledHeading style={{ marginTop: 0 }}>{orderInfoTitle}</StyledHeading>
      <StyledFormRow>
        <Select
          value={selectedFund}
          options={funds}
          isLoading={isLoadingFunds}
          width={tabletSize ? '100%' : SelectWidth.MEDIUM}
          valueKey="id"
          labelKey="name"
          inputId="fund-field"
          placeholder={chooseAFundLabel}
          label={chooseAFundLabel ?? ''}
          onChange={(fund) => {
            const data = fund as AppropriateFundResponseSchema;
            setFundId(data.id);
            setMinPurchaseAmount(data?.tradeDetails[0].minAmount);
          }}
        />
      </StyledFormRow>
      <StyledFormRow>
        <CurrencyInput
          name="amount"
          width={tabletSize ? '100%' : InputWidth.MEDIUM}
          label={amountToPurchaseLabel}
          value={amount}
          onBlur={() => {
            setAmountFieldDirty(true);
            updateValidAmount();
          }}
          onChange={(e) => {
            setAmountToPurchase(e.target.value);
            updateValidAmount(e.target.value);
          }}
          error={!isAmountValid && amountFieldDirty}
          helperText={getErrorText()}
        />
      </StyledFormRow>
      <StyledInformationText>{amountToPurchaseInformation}</StyledInformationText>
      {priceListLink && (
        <StyledLink>
          <a href={priceListLink} target="_blank" rel="noreferrer">
            Verðskrá sjóða
          </a>
        </StyledLink>
      )}
    </StyledContainer>
  );
};

export default SelectFundForm;
