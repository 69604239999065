import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppropriateFundResponseSchema, DisruptionTypeEnum, FundPurchaseRequestSchema } from '@kvika/api-types';
import { AxiosResponseHeaders } from 'axios';
import StepperNavigation from '../Navigation/StepperNavigation';
import { useSliceContext } from '../../SliceZone';
import { selectEntityState } from '../../../store/entity';
import { getKvikaApiClient } from '../../../utils/Utils';
import { SegmentTrackingId, trackEvent } from '../../../utils/Analytics';
import { selectSessionState } from '../../../store/session';
import { mapPreselectedFund } from '../../../utils/Funds';
import { hideModal, selectShowModal } from '../../../store/modal';
import SelectFundModals from './SelectFundModals';
import { Maybe, OnboardingPageBodySelect_FundPrimary } from '../../../types/PrismicTypes';
import SelectFundForm from './SelectFundForm';
import { selectStrings } from '../../../store/string';
import { getStringFromKey } from '../../../utils/Languages';
import { ErrorType } from '../../../types/Types';
import DisruptionModal from '../../DisruptionModal';
import { selectShowErrorModal } from '../../../store/error';

type Props = {
  captions: Maybe<OnboardingPageBodySelect_FundPrimary>;
};

type ServiceStatusModal = {
  header: string;
  body: string;
};

const SelectFundSlice: React.FC<Props> = ({ captions }: Props) => {
  const [forwardDisabled, setForwardDisabled] = React.useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState<boolean>(false);
  const [isLoadingFunds, setIsLoadingFunds] = React.useState<boolean>(false);
  const [purchaseRequest, setPurchaseRequest] = React.useState<FundPurchaseRequestSchema>();
  const [availableFunds, setAvailableFunds] = React.useState<AppropriateFundResponseSchema[]>([]);

  const [serviceStatusModal, setServiceStatusModal] = React.useState<ServiceStatusModal>({
    header: '',
    body: '',
  });
  const [showServiceStatusModal, setShowServiceStatusModal] = React.useState(false);
  const [fundId, setFundId] = React.useState<number>();
  const [startPurchaseFlow, setStartPurchaseFlow] = React.useState(false);
  const showModal = useSelector(selectShowModal);
  const showErrorModal = useSelector(selectShowErrorModal);
  const { goToPreviousPage, navigationPrimary, onError } = useSliceContext();
  const strings = useSelector(selectStrings);

  const entity = useSelector(selectEntityState);
  const { fundName, lang } = useSelector(selectSessionState);
  const dispatch = useDispatch();

  const onResponseHeaders = (headers: AxiosResponseHeaders) => {
    const disruptionType = headers['x-disruption-type'] as DisruptionTypeEnum;
    const message = headers['x-disruption-message'];

    if (disruptionType === DisruptionTypeEnum.EXTERNAL && strings) {
      const header = getStringFromKey({ strings, lang, key: ErrorType.ERROR_SORRY_FOR_THE_TROUBLE });
      const fallBackBody = getStringFromKey({ strings, lang, key: ErrorType.ERROR_SYSTEM_DISRUPTION });

      setServiceStatusModal({
        header,
        body: message || fallBackBody,
      });

      setShowServiceStatusModal(true);
      dispatch(hideModal());
      setIsLoadingSubmit(false);
    }
  };

  const apiClient = getKvikaApiClient(onResponseHeaders);

  React.useEffect(() => {
    if (fundName) {
      const mappedFundId = mapPreselectedFund(fundName);
      if (mappedFundId) {
        setFundId(mappedFundId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFunds]);

  React.useEffect(() => {
    // TODO: Only trigger this if we just finished onboarding, this does not apply if we have onboarded before
    // and land on OnboardingSelectFund screen
    trackEvent({
      event: SegmentTrackingId.OnboardingCompleted,
      properties: {},
    });
    trackEvent({
      event: SegmentTrackingId.BuyAssetInitiated,
      properties: {
        productName: fundName,
      },
    });
    if (entity.ssn) {
      setIsLoadingFunds(true);
      apiClient
        .getAppropriateFunds(entity.ssn)
        .then((response) => setAvailableFunds(response))
        .catch((error) => {
          onError(error);
        })
        .finally(() => setIsLoadingFunds(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavigateForward = () => {
    if (!forwardDisabled) {
      setStartPurchaseFlow(true);
    }
  };

  return (
    <>
      {showServiceStatusModal && !showModal && !showErrorModal && (
        <DisruptionModal
          header={serviceStatusModal.header}
          body={serviceStatusModal.body}
          onClose={() => setShowServiceStatusModal(false)}
          showModal={showServiceStatusModal}
          onConfirm={() => setShowServiceStatusModal(false)}
        />
      )}
      <SelectFundModals
        isLoadingSubmit={isLoadingSubmit}
        setIsLoadingSubmit={setIsLoadingSubmit}
        availableFunds={availableFunds}
        purchaseRequest={purchaseRequest}
        startPurchaseFlow={startPurchaseFlow}
        setStartPurchaseFlow={setStartPurchaseFlow}
      />
      <SelectFundForm
        orderInfoTitle={captions?.order_info_title ?? ''}
        chooseAFundLabel={captions?.choose_a_fund_label ?? ''}
        amountToPurchaseLabel={captions?.amount_to_purchase_label ?? ''}
        amountToPurchaseMinErrorText={captions?.amount_to_purchase_error_text ?? ''}
        amountToPurchaseMaxErrorText={captions?.amount_to_purchase_max_error_text ?? ''}
        amountToPurchaseInformation={captions?.amount_to_purchase_information ?? ''}
        onChange={(purchaseRequest) => setPurchaseRequest(purchaseRequest)}
        onValidationChange={(isValid) => {
          setForwardDisabled(!isValid);
        }}
        funds={availableFunds}
        setFundId={setFundId}
        fundId={fundId}
        priceListLink={captions?.price_list_link ?? ''}
        isLoadingFunds={isLoadingFunds}
      />
      {navigationPrimary && (
        <StepperNavigation
          onNavigateForward={onNavigateForward}
          onNavigateBackwards={goToPreviousPage}
          navigation={navigationPrimary}
          forwardDisabled={forwardDisabled}
          isLoadingSubmit={!showModal && isLoadingSubmit}
        />
      )}
    </>
  );
};

export default SelectFundSlice;
