import { InvestorTypeEnum } from '@kvika/api-types';

export type NavigationProps = {
  actingRetail?: boolean;
  investorType?: InvestorTypeEnum;
  isCompany?: boolean;
  isComplete?: boolean;
  isConfirmationBlocked?: boolean;
  isPepBlocked?: boolean;
  isRiskBlocked?: boolean;
  isSubmitted?: boolean;
  numberOfCompanies?: number;
  isBeneficialOwnersBlocked?: boolean;
};

type OneOfNavigation =
  | { nextPage: string; getNextPage?: never }
  | { getNextPage: (navigationProps: NavigationProps) => string; nextPage?: never };

type Navigation = {
  [key: string]: OneOfNavigation;
};

export const NavigationConstants = {
  EKKERT_FELAG: 'ekkert-tengt-felag',
  FANN_EKKI: 'sida-fannst-ekki',
  FYRIRTAEKI: 'fyrirtaeki',
  INNSKRANING: 'innskraning',
  TAKK_FYRIR_FJARFESTIR: 'takk-fyrir-fjarfestir',
  UMSOKN_MOTTEKIN: 'umsokn-mottekin',
  UMSOKN_PEP: 'umsokn-pep',
  UMSOKN_STADFESTING: 'umsokn-stadfesting',
  VELJA_SJOD: 'velja-sjod',
  SKRANING_MISRAEMI: 'skraning-misraemi',
};

const loginCheckForSingleEntityRouting = ({
  isRiskBlocked,
  isPepBlocked,
  actingRetail,
  isSubmitted,
  isBeneficialOwnersBlocked,
}: NavigationProps): string => {
  if ((isRiskBlocked || isPepBlocked) && isSubmitted) {
    return NavigationConstants.UMSOKN_PEP;
  }
  if (isBeneficialOwnersBlocked && isSubmitted) {
    return NavigationConstants.SKRANING_MISRAEMI;
  }
  if (actingRetail) {
    return NavigationConstants.UMSOKN_MOTTEKIN;
  }

  return NavigationConstants.VELJA_SJOD;
};

const NavigationTree: Navigation = {
  [NavigationConstants.INNSKRANING]: {
    getNextPage: ({ isCompany, isConfirmationBlocked, numberOfCompanies, ...rest }) => {
      if (isCompany) {
        if (numberOfCompanies === 0) {
          return NavigationConstants.EKKERT_FELAG;
        }
        if (numberOfCompanies === 1 && isConfirmationBlocked) {
          return NavigationConstants.UMSOKN_STADFESTING;
        }

        if (numberOfCompanies !== undefined && numberOfCompanies > 1) {
          return NavigationConstants.FYRIRTAEKI;
        }
      }
      return loginCheckForSingleEntityRouting(rest);
    },
  },
  [NavigationConstants.FYRIRTAEKI]: {
    getNextPage: ({ isConfirmationBlocked, isBeneficialOwnersBlocked, ...rest }) => {
      if (isBeneficialOwnersBlocked) {
        return NavigationConstants.SKRANING_MISRAEMI;
      }
      if (isConfirmationBlocked) {
        return NavigationConstants.UMSOKN_STADFESTING;
      }
      return loginCheckForSingleEntityRouting(rest);
    },
  },
  [NavigationConstants.UMSOKN_MOTTEKIN]: {
    nextPage: NavigationConstants.VELJA_SJOD,
  },
  [NavigationConstants.VELJA_SJOD]: {
    nextPage: NavigationConstants.TAKK_FYRIR_FJARFESTIR,
  },
};

export const getNextPage = (currentPage: string, props: NavigationProps) => {
  const page = NavigationTree[currentPage];
  if (page) {
    if (page.nextPage) {
      return `/${page.nextPage}`;
    }
    if (page.getNextPage) {
      return `/${page.getNextPage(props)}`;
    }
  }
  return null;
};

export const getRouterPath = (name: string) => {
  return `/${name}`;
};
