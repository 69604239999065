import Image from 'next/image';

import styled from 'styled-components';

// This is reccomended by Next.js https://nextjs.org/docs/api-reference/next/image#fill when using the fill property
export const StyledImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 50vw;
`;

// This is done to prevent the image from being squished in the NextJS 13 Image component
// These are basically just some of the old styles generated by the old (Nextjs 12) NextJS image component
// TODO: Find a more elegant solution to this
export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 0px;
`;
