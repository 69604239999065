import * as React from 'react';
import Input, { InputWidth } from '@kvika/input';
import NumberFormat from 'react-number-format';
import { UnknownObject } from '../../../types/Types';

type Props = {
  label?: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  inputRef?(ref: React.RefObject<HTMLInputElement>): void;
  name: string;
  value?: string | number;
  onBlur?: () => void;
  error?: boolean;
  style?: React.CSSProperties;
  helperText?: string;
  id?: string;
  width?: InputWidth | string;
};

const CurrencyInput: React.FunctionComponent<Props> = ({
  label,
  onChange,
  name,
  value,
  onBlur,
  inputRef = (ref) => ref,
  error,
  style,
  helperText,
  id = 'amount-input',
  width,
}) => {
  return (
    <NumberFormat
      label={label}
      style={style}
      id={id}
      customInput={Input}
      getInputRef={inputRef}
      onValueChange={(values: UnknownObject) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      decimalScale={0}
      value={value}
      onBlur={onBlur}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      suffix=" kr."
      type="tel"
      allowNegative={false}
      width={width}
      {...(error && helperText && { error: helperText })}
    />
  );
};
export default CurrencyInput;
