import { Banner } from '@kvika/header';
import styled from 'styled-components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@kvika/theme';
import { prettifySSN } from '@kvika/string-utils';
import { StyledButton } from './buttons/ButtonStyles';
import { selectEntityState } from '../../store/entity';
import { Banner as BannerTypes } from '../../types/PrismicTypes';

type Props = {
  banner: BannerTypes;
  onClick?(): void;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const StyledButtonText = styled.p`
  text-decoration: underline;
  font-weight: bold;
`;

const StyledButtonWrapper = styled.div`
  margin-left: ${Grid['8px']};
`;

const BannerWrapper = ({ banner, onClick }: Props): JSX.Element => {
  const entity = useSelector(selectEntityState);
  const userInfo = {
    ssn: entity.ssn,
    isLivingAbroad: entity.address.isLivingAbroad,
    name: entity.name,
  };
  const companyInfo = entity.isCompany
    ? {
        companySsn: entity.ssn,
        companyName: entity.name,
      }
    : null;
  const getText = () => {
    if (companyInfo) {
      return `${banner.on_behalf} ${companyInfo.companyName} (${prettifySSN(companyInfo.companySsn || '')}) `;
    }
    return `${banner.logged_in_as} ${userInfo?.name} ${prettifySSN(userInfo?.ssn || '')} `;
  };

  return (
    <Banner>
      <StyledContainer>
        {getText()}
        {onClick && entity.isCompany && (
          <StyledButtonWrapper>
            <StyledButton onClick={onClick}>
              <StyledButtonText>{banner.switch}</StyledButtonText>
            </StyledButton>
          </StyledButtonWrapper>
        )}
      </StyledContainer>
    </Banner>
  );
};

export default BannerWrapper;
