import Button from '@kvika/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSize } from '@kvika/modal';
import { decodeBase64String } from '@kvika/string-utils';
import React from 'react';

type Props = {
  header: string;
  body: string;
  onClose: () => void;
  onConfirm: () => void;
  showModal?: boolean;
  isLoadingSubmit?: boolean;
};

const DisruptionModal = ({ header, body, showModal, onClose, onConfirm, isLoadingSubmit }: Props) => {
  return (
    <Modal size={ModalSize.SMALL} isVisible={showModal} onClose={onClose}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{decodeBase64String(body)}</ModalBody>
      <ModalFooter>
        {showModal && (
          <Button onClick={onConfirm} loading={isLoadingSubmit} disabled={isLoadingSubmit}>
            Áfram
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DisruptionModal;
