import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import styled from 'styled-components';
import { SelectEntities, EntityType } from '@kvika/slices';
import { AddressSchema } from '@kvika/api-types';
import { ModalSize } from '@kvika/modal';
import { getOnboardingModal, getOnboardingStatus, getOnboardingUrl, OnboardingStatus } from '../../../utils/Utils';
import { setEntityState } from '../../../store/entity';
import { selectSessionState } from '../../../store/session';
import { getNextPage, NavigationConstants } from '../../../utils/Navigation';
import { selectCompaniesState } from '../../../store/companies';
import { useSliceContext } from '../../SliceZone';
import { Maybe, OnboardingPageBodySelect_EntitiesPrimary } from '../../../types/PrismicTypes';
import { ModalState } from '../../../types/Types';
import { displayModal, hideModal, selectShowModal } from '../../../store/modal';
import ModalSlice from '../Modal/ModalSlice';
import { clearSession, getAuthenticationToken } from '../../../utils/AuthenticationStorage';

type Props = {
  selectEntityContent: Maybe<OnboardingPageBodySelect_EntitiesPrimary>;
};

const StyledContainer = styled.div`
  line-height: normal;
  margin-top: 35px;
`;

const SelectEntiesSlice: React.FC<Props> = ({ selectEntityContent }: Props) => {
  const [currentModal, setCurrentModal] = React.useState<ModalState>();

  const dispatch = useDispatch();
  const {
    user: { phoneNumber },
  } = useSelector(selectSessionState);
  const { companies } = useSelector(selectCompaniesState);

  const { modalGroupFields } = useSliceContext();
  const showModal = useSelector(selectShowModal);

  const onEntitySelect = (entity: EntityType) => {
    const address = companies.find((e) => e.ssn === entity.ssn)?.address;
    loadEntity(entity, address);
  };

  const loadEntity = async (entity: EntityType, address?: AddressSchema) => {
    const repsonse = await getOnboardingStatus(entity.ssn);
    redirectOnEntitySelect(repsonse, entity, address);
  };

  const redirectOnEntitySelect = (
    response: OnboardingStatus,
    entity: EntityType,
    address: AddressSchema | undefined
  ) => {
    const isBlocked =
      response.isBeneficialOwnersBlocked ||
      response.isConfirmationBlocked ||
      response.isRiskBlocked ||
      response.isPepBlocked;

    if (!response.isComplete && !isBlocked) {
      displayRedirectModal(false, entity.ssn);
    } else {
      dispatch(
        setEntityState({
          isCompany: true,
          ssn: entity.ssn,
          name: entity.name,
          address,
        })
      );

      const nextPage = getNextPage(NavigationConstants.FYRIRTAEKI, { isCompany: true, ...response });
      if (nextPage) {
        router.push(nextPage);
      }
    }
  };

  const onConfirmRedirect = (companySsn: string) => {
    const authToken = getAuthenticationToken();
    clearSession();
    window.open(getOnboardingUrl(authToken, phoneNumber, true, companySsn), '_self');
  };

  const displayRedirectModal = (isNewUser: boolean, companySsn: string) => {
    const modalId = isNewUser ? 'SIGNUP_REDIRECT' : 'CONTINUE_SIGNUP_REDIRECT';
    const modal = {
      modalContent: getOnboardingModal(modalGroupFields ?? [], modalId),
      closeOnClickOutside: true,
      showCloseButton: true,
      onClose: () => {
        dispatch(hideModal());
      },
      size: ModalSize.SMALL,
      testId: 'redirect-modal',
      onConfirm: () => onConfirmRedirect(companySsn),
    } as ModalState;
    setCurrentModal(modal);
    dispatch(displayModal());
  };

  return (
    <StyledContainer>
      {showModal && (
        <ModalSlice
          closeOnClickOutside={currentModal?.closeOnClickOutside}
          showCloseButton={currentModal?.showCloseButton}
          autoFocus={currentModal?.autoFocus}
          size={currentModal?.size}
          modal={currentModal?.modalContent}
          onModalClose={currentModal?.onClose}
          onConfirm={currentModal?.onConfirm}
          onCancelButtonStyle={currentModal?.onCancelButtonStyle}
        />
      )}
      <SelectEntities
        onEntitySelect={onEntitySelect}
        selectButtonText={selectEntityContent?.select_button_text ?? ''}
        entities={companies}
        ssnText={selectEntityContent?.ssn_text ?? ''}
      />
    </StyledContainer>
  );
};

export default SelectEntiesSlice;
