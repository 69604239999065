import React from 'react';
import { Home } from '@kvika/icons';
import { Colors } from '@kvika/theme';
import { CustomOnboardingLinkType } from '../../../types/Types';
import { StyledGoldLink, StyledHomeBlack, StyledHomeGold, StyledLinkWrapper } from './CustomLinkSliceStyles';

interface IOnboardingCustomLinkProps {
  link_type: string;
  link_text: string;
  link_uri?: string;
}

const CustomLinkSlice: React.FunctionComponent<IOnboardingCustomLinkProps> = ({
  link_type,
  link_text,
  link_uri,
}: IOnboardingCustomLinkProps) => {
  switch (link_type) {
    case CustomOnboardingLinkType.Gold: {
      return (
        <StyledGoldLink rel="noreferrer" href={link_uri ?? ''}>
          {link_text}
        </StyledGoldLink>
      );
    }
    case CustomOnboardingLinkType.HomeGold: {
      return (
        <StyledLinkWrapper>
          <Home color={Colors.gold400} />
          <StyledHomeGold rel="noreferrer" href={link_uri ?? ''}>
            {link_text}
          </StyledHomeGold>
        </StyledLinkWrapper>
      );
    }
    case CustomOnboardingLinkType.HomeBlack: {
      return (
        <StyledLinkWrapper>
          <Home />
          <StyledHomeBlack rel="noreferrer" href={link_uri ?? ''}>
            {link_text}
          </StyledHomeBlack>
        </StyledLinkWrapper>
      );
    }
    default:
      return null;
  }
};

export default CustomLinkSlice;
