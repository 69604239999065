import React from 'react';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { useSelector } from 'react-redux';
import { Grid } from '@kvika/theme';
import StepperNavigation from '../Navigation/StepperNavigation';
import { useSliceContext } from '../../SliceZone';
import { selectSessionState } from '../../../store/session';
import { getTextWithTemplateVariables } from '../../../utils/Utils';
import { Maybe, Scalars } from '../../../types/PrismicTypes';

type Props = {
  title: string;
  description: Maybe<Scalars['Json']>;
};

export const StyledTitle = styled.h3`
  padding-bottom: ${Grid['16px']};
`;

const NoRelatedCompaniesSlice: React.FC<Props> = ({ title, description }: Props) => {
  const { goToPreviousPage, navigationPrimary } = useSliceContext();
  const { user } = useSelector(selectSessionState);
  return (
    <>
      <div>
        <StyledTitle>{title}</StyledTitle>
        <PrismicRichText field={getTextWithTemplateVariables(description, { phoneNumber: user.phoneNumber })} />
      </div>
      {navigationPrimary && (
        <StepperNavigation
          onNavigateForward={() => undefined}
          onNavigateBackwards={goToPreviousPage}
          navigation={navigationPrimary}
          forwardDisabled={false}
        />
      )}
    </>
  );
};

export default NoRelatedCompaniesSlice;
