import styled from 'styled-components';
import { Colors } from '@kvika/theme';

export const StyledGoldLink = styled.a`
  color: ${Colors.gold600};
`;

export const StyledHomeGold = styled.a`
  color: ${Colors.gray800};
  margin-left: 12px;
`;

export const StyledLinkWrapper = styled.div`
  padding-top: 96px;
`;

export const StyledHomeBlack = styled.a`
  color: ${Colors.gold600};
  margin-left: 12px;
`;
