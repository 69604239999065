import { Logout } from '@kvika/icons';
import * as React from 'react';
import { StyledButton } from './ButtonStyles';

type Props = {
  text: string;
  onClick(): void;
};

const LogoutButton = ({ text, onClick }: Props): JSX.Element => {
  return (
    <div>
      <StyledButton onClick={onClick}>
        {text} <Logout />
      </StyledButton>
    </div>
  );
};

export default LogoutButton;
