import styled, { css } from 'styled-components';
import { Grid } from '@kvika/theme';

type Props = {
  forwardButton: boolean;
  backButton: boolean;
  columnDirection?: boolean;
};

const getNavigationPlacement = ({ forwardButton, backButton }: Props) => {
  if (forwardButton && !backButton) {
    return css`
      justify-content: flex-end;
    `;
  }
  if (backButton && !forwardButton) {
    return css`
      justify-content: flex-start;
    `;
  }
  return css`
    justify-content: space-between;
  `;
};

const getNavigationAlignment = ({ columnDirection }: Props) => {
  if (columnDirection) {
    return css`
      flex-direction: column;
      height: 400px;
    `;
  }
  return css`
    flex-direction: row;
  `;
};

export const StyledNavigation = styled.div<Props>`
  margin-top: ${Grid['64px']};
  display: flex;
  ${getNavigationAlignment}
  ${getNavigationPlacement}
`;
