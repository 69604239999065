import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { AuthLoginResponseSchema, DisruptionTypeEnum } from '@kvika/api-types';
import { ModalSize } from '@kvika/modal';
import { AxiosError } from 'axios';
import { Login } from '@kvika/slices';
import {
  isMobilePhoneNumberValid,
  sanitisePhoneNumber,
  prettifyPhoneNumber,
  decodeBase64String,
} from '@kvika/string-utils';
import { ApiError } from '@kvika/api-client';
import { useMediaQuery } from '@kvika/hooks';
import { Grid, MediaQuery } from '@kvika/theme';
import StepperNavigation from '../Navigation/StepperNavigation';
import { useSliceContext } from '../../SliceZone';
import {
  getKvikaApiClient,
  getOnboardingModal,
  getOnboardingStatus,
  getAge,
  onAxiosError,
  OnboardingStatus,
  getOnboardingUrl,
} from '../../../utils/Utils';
import { identifyUser, SegmentTrackingId, trackEvent } from '../../../utils/Analytics';
import { selectShowModal, displayModal, hideModal } from '../../../store/modal';
import ModalSlice from '../Modal/ModalSlice';
import { displayError, clearErrorState } from '../../../store/error';
import { getNextPage, NavigationConstants } from '../../../utils/Navigation';
import { loginSuccessful, updateFundName } from '../../../store/session';
import { setEntityState } from '../../../store/entity';

import { setCompanyState } from '../../../store/companies';
import { Maybe, OnboardingPageBodyLoginPrimary } from '../../../types/PrismicTypes';
import { clearSession, getAuthenticationToken, setExternalId } from '../../../utils/AuthenticationStorage';
import { ErrorCodes, ErrorType, ModalState } from '../../../types/Types';

type Props = {
  captions: Maybe<OnboardingPageBodyLoginPrimary>;
};

const StyledNavigation = styled.div`
  max-width: 448px;
`;

const StyledStepperNavigation = styled(StepperNavigation)`
  justify-content: flex-start;
  margin-top: 20px;
`;

const StyledSignup = styled.div`
  margin-top ${Grid['16px']}

`;

const LoginScreenSlice: React.FC<Props> = ({ captions }: Props) => {
  const [currentModal, setCurrentModal] = useState<ModalState>();
  const [forwardDisabled, setForwardDisabled] = useState<boolean>(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [isCompany, setIsCompany] = useState<boolean>(false);
  const { goToPreviousPage, navigationPrimary, modalGroupFields } = useSliceContext();
  const isMobile = useMediaQuery(`(max-width: ${MediaQuery.Mobile})`);
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowModal);

  const apiClient = getKvikaApiClient();

  const trackLoginEvent = (
    phoneNumber: string,
    segmentTrackingId: SegmentTrackingId,
    externalId?: string,
    ssn?: string
  ) => {
    const baseProperties = {
      phoneNumber,
    };

    const age = getAge(ssn || '');

    const userProperties = {
      age,
      phoneNumber,
    };
    identifyUser(externalId || '', userProperties);

    const properties = externalId ? { ...baseProperties, externalId } : baseProperties;
    trackEvent({
      event: segmentTrackingId,
      properties,
    });
  };

  const displayLoginModal = () => {
    const modal = {
      modalContent: getOnboardingModal(modalGroupFields ?? [], 'LOGIN_PHONE_EID', {
        phoneNumber: prettifyPhoneNumber(phoneNumber),
      }),
      closeOnClickOutside: false,
      showCloseButton: false,
      onClose: () => {
        dispatch(hideModal());
        setIsLoadingSubmit(false);
      },
      size: ModalSize.SMALL,
      testId: 'login-modal',
    } as ModalState;
    setCurrentModal(modal);
    dispatch(displayModal());
  };

  const onConfirmRedirect = () => {
    const authToken = getAuthenticationToken();
    clearSession();
    window.open(getOnboardingUrl(authToken, phoneNumber, isCompany), '_self');
  };

  const displayRedirectModal = (isNewUser?: boolean) => {
    const modalId = isNewUser ? 'SIGNUP_REDIRECT' : 'CONTINUE_SIGNUP_REDIRECT';
    const modal = {
      modalContent: getOnboardingModal(modalGroupFields ?? [], modalId),
      closeOnClickOutside: true,
      showCloseButton: true,
      onClose: () => {
        dispatch(hideModal());
        setIsLoadingSubmit(false);
      },
      size: ModalSize.SMALL,
      testId: 'redirect-modal',
      onConfirm: onConfirmRedirect,
    } as ModalState;
    setCurrentModal(modal);
    dispatch(displayModal());
  };

  const onNavigateForward = () => {
    if (!forwardDisabled) {
      const phoneNumberSanitised = sanitisePhoneNumber(phoneNumber);
      setPhoneNumber(phoneNumberSanitised);
      if (isMobilePhoneNumberValid(phoneNumberSanitised)) {
        setIsLoadingSubmit(true);
        displayLoginModal();
        apiClient
          .login({ identifier: phoneNumberSanitised })
          .then((response: AuthLoginResponseSchema) => {
            onLoginSuccess(phoneNumber, response);
          })
          .catch((error) => {
            dispatch(hideModal());
            setIsLoadingSubmit(false);
            onLoginError(error);
          });
      }
    }
  };

  const onLoginSuccess = (phoneNumber: string, response: AuthLoginResponseSchema) => {
    trackLoginEvent(phoneNumber, SegmentTrackingId.LoginCompleted, response.externalId, response.ssn);
    setExternalId(response.externalId);
    const data = {
      ssn: response.ssn,
      name: response.fullName,
      address: response.address,
      email: response.email ?? '',
      phoneNumber,
      isCompany,
    };

    dispatch(loginSuccessful(data));
    if (!isCompany) {
      dispatch(setEntityState(data));
    }

    redirectOnLogin(response.ssn);
  };

  const redirectToOnboarding = (data: OnboardingStatus) => {
    const isNewUser = data.isNotFound;
    displayRedirectModal(isNewUser);
  };

  const routeForCompanies = async (data: OnboardingStatus) => {
    await apiClient
      .getCompanies()
      .then(async (response) => {
        if (response.length === 1) {
          const company = response[0];
          const companyStatus = await getOnboardingStatus(company.ssn);
          const isBlocked =
            companyStatus.isBeneficialOwnersBlocked ||
            companyStatus.isConfirmationBlocked ||
            companyStatus.isRiskBlocked ||
            companyStatus.isPepBlocked;
          if (!data.isComplete && !isBlocked) {
            redirectToOnboarding(data);
            return;
          }
        }
        dispatch(setCompanyState({ companies: response }));
        const nextPage = getNextPage(NavigationConstants.INNSKRANING, {
          isCompany,
          numberOfCompanies: response.length,
          ...data,
        });
        if (nextPage) {
          router.push(nextPage);
        }
      })
      .catch((error: AxiosError) => {
        onAxiosError(error, dispatch);
      });
  };

  const redirectOnLogin = async (ssn: string) => {
    const data = await getOnboardingStatus(ssn);
    const isBlocked =
      data.isBeneficialOwnersBlocked || data.isConfirmationBlocked || data.isRiskBlocked || data.isPepBlocked;
    if (!data.isComplete && !isCompany && !isBlocked) {
      redirectToOnboarding(data);
    } else {
      dispatch(hideModal());
      dispatch(clearErrorState());
      if (isCompany) {
        routeForCompanies(data);
      } else {
        const nextPage = getNextPage(NavigationConstants.INNSKRANING, {
          isCompany,
          ...data,
        });

        if (!data.isSubmitted && !data.isComplete) {
          trackEvent({
            event: SegmentTrackingId.OnboardingStarted,
            properties: {},
          });
        }
        if (nextPage !== null) {
          router.push(nextPage);
        }
      }
    }
  };

  useEffect(() => {
    const { fund } = query;
    if (typeof fund === 'string') {
      dispatch(updateFundName(fund));
    }
  }, [query, dispatch]);

  const onLoginError = (error: ApiError) => {
    if (error && error.response) {
      const { headers } = error.response;
      const disruptionType = headers['x-disruption-type'] as DisruptionTypeEnum;
      const message = decodeBase64String(headers['x-disruption-message']);

      if (disruptionType === DisruptionTypeEnum.MAINTENANCE) {
        dispatch(
          displayError({
            showErrorModal: true,
            modalErrorHeaderKey: ErrorType.ERROR_SYSTEM_UPDATE,
            modalCustomErrorBodyText: message,
          })
        );
      } else {
        const hasNoElectronicId = error?.response?.data?.code === ErrorCodes.PhoneNumberHasNoElectronicId;
        const errorKey = hasNoElectronicId
          ? ErrorType.ERROR_NUMBER_DOES_NOT_HAVE_EID
          : ErrorType.ERROR_ELECTRONIC_ID_FAILED;

        dispatch(displayError({ showErrorModal: true, modalErrorBodyKey: errorKey }));
      }
    }

    trackLoginEvent(phoneNumber, SegmentTrackingId.LoginFailed);

    captureException(error);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !forwardDisabled) {
      onNavigateForward();
    }
  };

  const getTabs = () => {
    return process.env.NEXT_PUBLIC_HIDE_LEGAL_ENTITIES
      ? [{ title: captions?.tab_individual ?? '', id: 0 }]
      : [
          { title: captions?.tab_individual ?? '', id: 0 },
          { title: captions?.tab_legal_entity ?? '', id: 1 },
        ];
  };

  const renderSignupText = () => {
    const signupText = captions?.signup_key_text ?? '';
    const beforeText = signupText.substring(0, signupText.indexOf('{'));
    const afterText = signupText.substring(signupText.indexOf('}') + 1);
    return (
      <StyledSignup>
        <div>
          {beforeText}
          <a href={process.env.NEXT_PUBLIC_ONBOARDING_URL}>{captions?.signup_link_text}</a>
          {afterText}
        </div>
      </StyledSignup>
    );
  };

  return (
    <>
      {showModal && (
        <ModalSlice
          closeOnClickOutside={currentModal?.closeOnClickOutside}
          showCloseButton={currentModal?.showCloseButton}
          autoFocus={currentModal?.autoFocus}
          size={currentModal?.size}
          modal={currentModal?.modalContent}
          onModalClose={currentModal?.onClose}
          onConfirm={currentModal?.onConfirm}
          onCancelButtonStyle={currentModal?.onCancelButtonStyle}
        />
      )}

      <Login
        translations={{
          loginTitle: captions?.login_title ?? '',
          loginSubtitle: captions?.login_subtitle ?? '',
          phoneNumberLabel: captions?.phone_number_label ?? '',
          invalidPhoneNumberMsg: captions?.invalid_phone_number_message ?? '',
        }}
        onValidationChange={(isValid) => setForwardDisabled(!isValid)}
        onChange={(phoneNumber, selectedTab) => {
          setPhoneNumber(phoneNumber);
          setIsCompany(selectedTab === 1);
        }}
        onKeyPress={onKeyPress}
        width="450px"
        tabs={getTabs()}
        defaultSelectedTab={0}
      />
      {renderSignupText()}
      {navigationPrimary && (
        <StyledNavigation>
          <StyledStepperNavigation
            onNavigateForward={onNavigateForward}
            onNavigateBackwards={goToPreviousPage}
            navigation={navigationPrimary}
            forwardDisabled={forwardDisabled}
            isLoadingSubmit={isLoadingSubmit}
            forwardButtonWidth={isMobile ? '100%' : '40%'}
          />
        </StyledNavigation>
      )}
    </>
  );
};

export default LoginScreenSlice;
