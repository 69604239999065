import styled from 'styled-components';
import { MediaQuery } from '@kvika/theme';

export const StyledContent = styled.div`
  @media (min-width: ${MediaQuery.Laptop}) {
    max-width: 75%;
  }
`;

export const StyledNavigation = styled.div`
  @media (min-width: ${MediaQuery.Tablet}) {
    max-width: 90%;
  }
`;
