import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBackgroundType } from '@kvika/button';
import { toIskCurrencyString, prettifyPhoneNumber } from '@kvika/string-utils';
import { AppropriateFundResponseSchema, FundPurchaseRequestSchema } from '@kvika/api-types';
import { displayModal, hideModal, selectShowModal } from '../../../store/modal';
import { ErrorType, ModalState, ModalTypes, ServerErrors } from '../../../types/Types';
import { getKvikaApiClient, getOnboardingModal } from '../../../utils/Utils';
import OnboardingModal from '../Modal/ModalSlice';
import { useSliceContext } from '../../SliceZone';
import { SegmentTrackingId, trackEvent } from '../../../utils/Analytics';
import { getExternalId } from '../../../utils/AuthenticationStorage';
import { selectSessionState } from '../../../store/session';
import { displayError } from '../../../store/error';

type Props = {
  setIsLoadingSubmit(isLoadingSubmit: boolean): void;
  isLoadingSubmit: boolean;
  purchaseRequest?: FundPurchaseRequestSchema;
  availableFunds: AppropriateFundResponseSchema[];
  setStartPurchaseFlow(value: boolean): void;
  startPurchaseFlow: boolean;
};

const SelectFundModals: React.FC<Props> = ({
  setIsLoadingSubmit,
  isLoadingSubmit,
  purchaseRequest,
  availableFunds,
  setStartPurchaseFlow,
  startPurchaseFlow,
}: Props) => {
  const [currentModal, setCurrentModal] = useState<ModalState>();
  const showModal = useSelector(selectShowModal);
  const apiClient = getKvikaApiClient();
  const dispatch = useDispatch();
  const { user } = useSelector(selectSessionState);

  const { onError, modalGroupFields, goToNextPage } = useSliceContext();

  useEffect(() => {
    if (startPurchaseFlow) {
      isPurchasingAppropriateFund() ? postPurchaseRequest(false) : triggerInappropriateFundModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPurchaseFlow]);

  const signDocument = (documentId: number) => {
    const modal = {
      modalContent: getOnboardingModal(modalGroupFields ?? [], ModalTypes.DOCUMENT_SIGNING, {
        phoneNumber: prettifyPhoneNumber(user.phoneNumber),
      }),
      closeOnClickOutside: false,
      showCloseButton: false,
      onClose: cleanup,
      testId: 'document-signing-modal',
    };
    setCurrentModal(modal);

    apiClient
      .signDocument(documentId)
      .then(() => {
        postPurchaseRequest(true, documentId);
      })
      .catch((error) => {
        if (error?.response?.status === 406 && error?.response?.data?.detail === ServerErrors.UserCancelation) {
          dispatch(hideModal());
          dispatch(
            displayError({
              showErrorModal: true,
              modalErrorHeaderKey: ErrorType.ERROR_OCCURRED,
              modalErrorBodyKey: ErrorType.ERROR_USER_CANCELLATION,
            })
          );
          setIsLoadingSubmit(false);
        } else {
          onError(error);
        }
        cleanup();
      });
  };

  const getFundName = (fundId?: number) => {
    const fund = availableFunds?.find((fund) => fund.id === fundId);
    return fund ? fund.name : '';
  };

  const triggerRelinquishRightsModal = () => {
    const onConfirm = () => {
      if (purchaseRequest && purchaseRequest.fundId) {
        const fund = availableFunds.find((f) => f.id === purchaseRequest.fundId);

        trackEvent({
          event: SegmentTrackingId.RelinquishRightsModal,
          properties: {
            amount: purchaseRequest.amount,
            fund: fund?.name,
            externalId: getExternalId(),
          },
        });
        setIsLoadingSubmit(true);
        apiClient
          .postRelinquishRights(purchaseRequest)
          .then((response) => {
            setIsLoadingSubmit(false);
            signDocument(response.id);
          })
          .catch((error) => {
            cleanup();
            setIsLoadingSubmit(false);
            onError(error);
          });
      }
    };
    const modal = {
      modalContent: getOnboardingModal(modalGroupFields ?? [], ModalTypes.RELINQUISH_RIGHTS, {
        buyAmount: `${toIskCurrencyString(purchaseRequest?.amount ?? 0)}`,
        fundName: getFundName(purchaseRequest?.fundId),
      }),
      onConfirm,
      onConfirmButtonStyle: ButtonBackgroundType.Filled,
      onCancelButtonStyle: ButtonBackgroundType.Outlined,
      onClose: cleanup,
      testId: 'relinquishRightsModal',
    };

    setCurrentModal(modal);
  };

  const triggerInappropriateFundModal = () => {
    dispatch(displayModal());
    if (purchaseRequest && purchaseRequest.fundId) {
      const fund = availableFunds.find((f) => f.id === purchaseRequest.fundId);

      trackEvent({
        event: SegmentTrackingId.InappropriateFundModal,
        properties: {
          amount: purchaseRequest.amount,
          fund: fund?.name,
          externalId: getExternalId(),
        },
      });
    }
    const modal = {
      modalContent: getOnboardingModal(modalGroupFields ?? [], ModalTypes.INAPPROPRIATE_FUND),
      onConfirm: triggerRelinquishRightsModal,
      onConfirmButtonStyle: ButtonBackgroundType.Outlined,
      onCancelButtonStyle: ButtonBackgroundType.Filled,
      onClose: cleanup,
      testId: 'inappropriateFundModal',
    };

    setCurrentModal(modal);
  };

  const cleanup = () => {
    dispatch(hideModal());
    setStartPurchaseFlow(false);
  };

  const postPurchaseRequest = (promptModal?: boolean, documentId?: number) => {
    if (purchaseRequest) {
      setIsLoadingSubmit(true);
      const fund = availableFunds.find((f) => f.id === purchaseRequest.fundId);
      apiClient
        .postPurchaseRequest({ ...purchaseRequest, ...(documentId && { documentId }) })
        .then(() => {
          trackEvent({
            event: SegmentTrackingId.BuyAssetSuccess,
            properties: {
              amount: purchaseRequest.amount,
              productName: fund?.name,
              externalId: getExternalId(),
            },
          });

          if (promptModal) {
            const modal = {
              modalContent: getOnboardingModal(modalGroupFields ?? [], ModalTypes.DOCUMENT_SIGNING_SUCCEEDED),
              onClose: () => {
                goToNextPage();
                cleanup();
              },
              testId: 'document-signing-succeeded-modal',
            };
            setCurrentModal(modal);
            setIsLoadingSubmit(false);
          } else {
            goToNextPage();
            setIsLoadingSubmit(false);
            cleanup();
          }
        })
        .catch((error) => {
          cleanup();
          setIsLoadingSubmit(false);
          onError(error);
          trackEvent({
            event: SegmentTrackingId.BuyAssetFailed,
            properties: {
              productName: fund?.name,
              amount: purchaseRequest.amount,
              error,
            },
          });
        });
    }
  };

  const isPurchasingAppropriateFund = () => {
    const fund = availableFunds.find((fund) => purchaseRequest?.fundId === fund.id);
    return fund?.appropriate;
  };

  return showModal && currentModal ? (
    <OnboardingModal
      closeOnClickOutside={currentModal.closeOnClickOutside}
      showCloseButton={currentModal.showCloseButton}
      modal={currentModal.modalContent}
      onModalClose={currentModal.onClose}
      onConfirm={currentModal.onConfirm}
      isLoadingSubmit={isLoadingSubmit && showModal}
      onConfirmButtonStyle={currentModal.onConfirmButtonStyle}
      onCancelButtonStyle={currentModal.onCancelButtonStyle}
      testId={currentModal.testId}
    />
  ) : null;
};

export default SelectFundModals;
