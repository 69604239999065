import { Colors, Grid } from '@kvika/theme';
import styled from 'styled-components';

export const StyledTextContainer = styled.div`
  margin-left: ${Grid['8px']};
`;

export const StyledHome = styled.a`
  margin-left: 12px;
  color: ${Colors.gray900};
`;
