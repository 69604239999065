/* eslint react/no-array-index-key: 0 */
import React, { createContext, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorTypeEnum, CapitalMarketsOnboardingResponseSchema } from '@kvika/api-types';
import { ApiError } from '@kvika/api-client';
import { updateError } from '../store/error';
import { sanitizeOnboardingAnswers, getKvikaApiClient, parseApiError } from '../utils/Utils';
import { selectOnboardingAnswers, setOnboardingAnswers } from '../store/answer';
import { selectSessionState } from '../store/session';
import { Slice, SliceState, NextPageProps } from '../types/Types';
import { getNextPage, NavigationConstants } from '../utils/Navigation';
import CustomLinkSlice from './slices/CustomLink/CustomLinkSlice';
import { selectEntityState } from '../store/entity';
import SelectEntitiesSlice from './slices/SelectEntities/SelectEntitiesSlice';
import LoginScreenSlice from './slices/Login/LoginScreenSlice';
import SelectFundSlice from './slices/SelectFund/SelectFundSlice';
import NoRelatedCompaniesSlice from './slices/NoRelatedCompanies/NoRelatedCompaniesSlice';
import {
  OnboardingPageBodyNavigation,
  OnboardingPageBodyModalgroup,
  OnboardingPageBodyNavigationPrimary,
  OnboardingPageBodyCustom_Links,
  OnboardingPageBodySelect_Entities,
  OnboardingPageBodyLogin,
  OnboardingPageBodySelect_Fund,
  OnboardingPageBodyApplication_Received,
  OnboardingPageBodyNo_Related_Companies,
} from '../types/PrismicTypes';
import { isNotFoundError } from '../utils/ErrorUtils';
import ApplicationReceivedSlice from './slices/ApplicationReceived/ApplicationReceivedSlice';

type Props = {
  slices: Slice[];
  navigationSlice?: OnboardingPageBodyNavigation;
  modalGroupSlice?: OnboardingPageBodyModalgroup;
};

const initialState = {
  onError: () => undefined,
  navigation: {} as OnboardingPageBodyNavigationPrimary,
  modalGroupFields: [],
  goToNextPage: () => undefined,
  goToPreviousPage: () => undefined,
};

const SliceZoneContext = createContext<SliceState>(initialState);

export const useSliceContext = (): SliceState => useContext(SliceZoneContext);

const SliceZone: React.FC<Props> = ({ slices, navigationSlice, modalGroupSlice }: Props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const apiClient = getKvikaApiClient();
  const entity = useSelector(selectEntityState);
  const onboardingAnswers = useSelector(selectOnboardingAnswers);
  const { lang } = useSelector(selectSessionState);
  const [onboardingResponse, setOnboardingResponse] = React.useState<
    CapitalMarketsOnboardingResponseSchema | Record<string, unknown>
  >({});

  const getCurrentPage = () => {
    if (router.route === '/') {
      // really not needed login handles its own routing login but fallback just in case
      return NavigationConstants.INNSKRANING;
    }
    return router.asPath.replace('/', '');
  };

  const getNavigationPrimary = () => {
    const { primary } = navigationSlice as OnboardingPageBodyNavigation;
    return primary;
  };

  const getModalGroupFields = () => {
    const { fields } = modalGroupSlice as OnboardingPageBodyModalgroup;
    return fields;
  };

  const navigationPrimary = navigationSlice ? getNavigationPrimary() : null;
  const modalGroupFields = modalGroupSlice ? getModalGroupFields() : null;

  useEffect(() => {
    const containsLoginSlice = slices.some((slice: Slice) => slice.type === 'login');
    if (!containsLoginSlice && entity.ssn && !onboardingAnswers) {
      apiClient
        .getCapitalMarketsOnboarding(entity.ssn)
        .then((response) => {
          setOnboardingResponse(response);
          const answers = sanitizeOnboardingAnswers(response, lang);
          dispatch(setOnboardingAnswers(answers));
        })
        .catch((error: ApiError) => {
          if (!isNotFoundError(error)) {
            onError(error);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.ssn]);

  const onError = (error: ApiError) => {
    dispatch(updateError(parseApiError(error)));
  };

  const goToNextPage = (props?: NextPageProps) => {
    // You can provide an optional response when the of the latest update directly effects
    // routing to the next page, only used in Terms & Categorization currently
    const { onboardingData } = props || {};
    const dataToUse = onboardingData || onboardingResponse;
    const nextPage = getNextPage(getCurrentPage(), {
      isCompany: entity.isCompany,
      ...dataToUse,
    });
    if (nextPage) {
      router.push(nextPage);
    }
  };

  const goToPreviousPage = () => {
    if (
      onboardingAnswers &&
      onboardingAnswers.isComplete &&
      onboardingAnswers.investorType === InvestorTypeEnum.PROFESSIONAL
    ) {
      dispatch(setOnboardingAnswers({ ...onboardingAnswers, isComplete: false }));
    }
    router.back();
  };

  return (
    <SliceZoneContext.Provider
      // eslint-disable-next-line
      value={{ onError, goToNextPage, goToPreviousPage, navigationPrimary, modalGroupFields }}
    >
      {slices.map((slice, index) => {
        switch (slice.type) {
          case 'custom_links': {
            const { primary } = slice as OnboardingPageBodyCustom_Links;
            return (
              <CustomLinkSlice
                key={index}
                link_text={primary?.link_text ?? ''}
                link_type={primary?.link_type ?? ''}
                link_uri={primary?.link_uri ?? ''}
              />
            );
          }
          case 'select_entities': {
            const { primary } = slice as OnboardingPageBodySelect_Entities;
            return <SelectEntitiesSlice selectEntityContent={primary} key={index} />;
          }
          case 'login': {
            const { primary } = slice as OnboardingPageBodyLogin;
            return <LoginScreenSlice captions={primary} key={index} />;
          }
          case 'select_fund': {
            const { primary } = slice as OnboardingPageBodySelect_Fund;
            return <SelectFundSlice captions={primary} key={index} />;
          }
          case 'application_received': {
            const { primary } = slice as OnboardingPageBodyApplication_Received;
            return (
              <ApplicationReceivedSlice
                key={index}
                title={primary?.application_received_title ?? ''}
                description={primary?.application_received_description}
              />
            );
          }
          case 'no_related_companies': {
            const { primary } = slice as OnboardingPageBodyNo_Related_Companies;
            return (
              <NoRelatedCompaniesSlice
                key={index}
                title={primary?.no_related_companies_title ?? ''}
                description={primary?.no_related_companies_description}
              />
            );
          }
          default:
            // eslint-disable-next-line
            console.log('Tried rendering slice: ', slice.type);
            return null;
        }
      })}
    </SliceZoneContext.Provider>
  );
};

export default SliceZone;
