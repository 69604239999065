import React from 'react';
import Modal, { ModalBody, ModalHeader, ModalFooter, ModalSize } from '@kvika/modal';
import { useSelector } from 'react-redux';
import Button, { ButtonBackgroundType } from '@kvika/button';
import { PrismicRichText } from '@prismicio/react';
import { selectShowModal } from '../../../store/modal';
import { OnboardingPageBodyModalgroupFields } from '../../../types/PrismicTypes';
import { selectShowErrorModal } from '../../../store/error';

type Props = {
  modal: OnboardingPageBodyModalgroupFields | undefined;
  onModalClose?: () => void;
  onConfirm?: () => void;
  isLoadingSubmit?: boolean;
  onConfirmButtonStyle?: ButtonBackgroundType;
  onCancelButtonStyle?: ButtonBackgroundType;
  size?: ModalSize;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
  autoFocus?: boolean;
  testId?: string;
  confirmButtonWidth?: string;
};

const ModalSlice: React.FC<Props> = ({
  modal,
  size = ModalSize.MEDIUM,
  onConfirm,
  onModalClose = () => null,
  isLoadingSubmit,
  onConfirmButtonStyle,
  onCancelButtonStyle,
  showCloseButton = true,
  closeOnClickOutside = true,
  autoFocus = true,
  testId,
  confirmButtonWidth,
}: Props) => {
  const showModal = useSelector(selectShowModal);
  const showErrorModal = useSelector(selectShowErrorModal);

  return modal && showModal && !showErrorModal ? (
    <Modal
      closeOnClickOutside={closeOnClickOutside}
      autoFocus={autoFocus}
      size={size}
      isVisible={showModal}
      onClose={onModalClose}
      testId={testId}
    >
      <ModalHeader showCloseButton={showCloseButton}>{modal.caption}</ModalHeader>
      <ModalBody>
        <PrismicRichText field={modal.modal_text} />
      </ModalBody>
      <ModalFooter>
        {modal.cancel_button_text && (
          <Button onClick={onModalClose} disabled={isLoadingSubmit} backgroundType={onCancelButtonStyle}>
            {modal.cancel_button_text}
          </Button>
        )}
        {onConfirm && modal.confirm_button_text && (
          <Button
            onClick={onConfirm}
            loading={isLoadingSubmit}
            disabled={isLoadingSubmit}
            backgroundType={onConfirmButtonStyle}
            width={confirmButtonWidth}
          >
            {modal.confirm_button_text}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  ) : null;
};

export default ModalSlice;
