import * as React from 'react';
import { MediaQuery } from '@kvika/theme';
import { valueOrDefault } from '../../utils/Prismic/PrismicBaseUtils';
import { StyledImage, StyledImageWrapper } from './LayoutImageStyles';

type Props = {
  url: string;
  alt?: string;
  quality?: number;
};

const LayoutImage = ({ url, alt = '', quality = 75 }: Props) => {
  if (!url) {
    return null;
  }

  return (
    <StyledImageWrapper>
      <StyledImage
        src={valueOrDefault(url, '')}
        quality={quality}
        alt={alt}
        priority
        fill
        sizes={`(min-width: ${MediaQuery.Laptop}) 50vw,
        display: none;`}
      />
    </StyledImageWrapper>
  );
};

export default LayoutImage;
