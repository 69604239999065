import { PrismicRichText } from '@prismicio/react';
import * as React from 'react';
import { valueOrDefault } from '../../utils/Prismic/PrismicBaseUtils';
import { StyledH3, StyledContentWrapper, StyledDescription } from './LayoutTextHeaderStyles';
import { Maybe, Scalars } from '../../types/PrismicTypes';

type Props = {
  title?: string | null;
  description?: Maybe<Scalars['Json']>;
};

const LayoutTextHeader = ({ title, description }: Props) => {
  return (
    <StyledContentWrapper>
      <StyledH3>{valueOrDefault(title, '')}</StyledH3>
      <StyledDescription>
        <PrismicRichText field={description} />
      </StyledDescription>
    </StyledContentWrapper>
  );
};

export default LayoutTextHeader;
