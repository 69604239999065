import styled from 'styled-components';
import { Grid } from '@kvika/theme';

export const StyledH3 = styled.h3`
  margin: 0 0 16px 0;
`;

export const StyledContentWrapper = styled.div`
  display: inline;
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  display: inherit;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-size: ${Grid['16px']};
  text-align: left;
`;
