import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Layout, { ChildrenWrapper, JustifyContentType } from '@kvika/layout';
import Header from '@kvika/header';
import { ProgressStepper } from '@kvika/progress';
import Button from '@kvika/button';
import { logout, selectSessionState, updateLanguage } from '../../store/session';
import { selectEntityState } from '../../store/entity';
import { getKvikaApiClient, shouldDisplayClearAnswers, shouldDisplayLanguagePicker } from '../../utils/Utils';
import { SegmentTrackingId, trackEvent } from '../../utils/Analytics';
import LogoutButton from './buttons/LogoutButton';
import LanguagePicker from './buttons/LanguagePicker/LanguagePicker';
import BannerWrapper from './BannerWrapper';
import { OnboardingPage, ProgressStepper as ProgressStepperType, Step, Banner } from '../../types/PrismicTypes';
import { Lang } from '../../types/Types';
import { clearSession } from '../../utils/AuthenticationStorage';
import LayoutTextHeader from './LayoutTextHeader';
import LayoutImage from './LayoutImage';
import { getRouterPath, NavigationConstants } from '../../utils/Navigation';

type Props = {
  onboardingPage: OnboardingPage;
  children?: React.ReactNode | React.ReactNode[];
};

export const LayoutWrapper = ({ onboardingPage, children }: Props) => {
  const [isClearAnswersLoading, setIsClearAnswersLoading] = React.useState(false);
  const router = useRouter();
  const entity = useSelector(selectEntityState);
  const { loggedIn, lang } = useSelector(selectSessionState);
  const dispatch = useDispatch();
  const apiClient = getKvikaApiClient();

  const onLogout = () => {
    dispatch(logout());
    clearSession();
    router.push('/');
  };

  const handleUpdateLang = (lang: Lang) => {
    dispatch(updateLanguage(lang));
    trackEvent({
      event: SegmentTrackingId.LanguageChangedTo.replace('XX', lang) as SegmentTrackingId,
      properties: { language: lang },
    });
  };

  const onClearAnswers = async () => {
    setIsClearAnswersLoading(true);
    if (entity.isCompany) {
      if (entity.ssn) {
        await apiClient.clearCompanyAnswersOnStaging(entity.ssn);
      }
    } else {
      await apiClient.clearAnswersOnStaging();
    }
    setIsClearAnswersLoading(false);
    onLogout();
  };

  const getSteps = () => {
    const progressStepper = onboardingPage.progress_stepper as ProgressStepperType;
    return progressStepper.steps
      ? progressStepper.steps?.map((step) => {
          const innerStep = step.step as Step;
          return {
            id: innerStep.number ?? 0,
            label: innerStep.title ?? '',
          };
        })
      : [];
  };

  const getCurrentStep = () => {
    const step = onboardingPage.step as Step;
    const progressStepper = onboardingPage.progress_stepper as ProgressStepperType;
    return (
      progressStepper.steps?.findIndex(({ step: data }) => {
        const test = data as unknown as Step;
        return test.title === step.title;
      }) || 0
    );
  };

  return (
    <Layout image={<LayoutImage url={onboardingPage.background.url} alt={onboardingPage.background.alt} />}>
      <Header>
        {shouldDisplayClearAnswers(onboardingPage.show_clear_answers_button ?? false) && (
          <div>
            <Button loading={isClearAnswersLoading} onClick={onClearAnswers}>
              Hreinsa svör
            </Button>
          </div>
        )}
        {shouldDisplayLanguagePicker(onboardingPage.show_language_picker ?? false) && (
          <LanguagePicker lang={lang} handleUpdateLang={handleUpdateLang} />
        )}
        {loggedIn && <LogoutButton text="Útskrá" onClick={onLogout} />}
      </Header>
      {onboardingPage.progress_stepper && <ProgressStepper steps={getSteps()} currentStepIndex={getCurrentStep()} />}
      {onboardingPage.banner && (
        <BannerWrapper
          banner={onboardingPage.banner as Banner}
          onClick={() => {
            router.push(getRouterPath(NavigationConstants.FYRIRTAEKI));
          }}
        />
      )}
      <ChildrenWrapper justifyContent={onboardingPage.justify_content as JustifyContentType}>
        <LayoutTextHeader title={onboardingPage.title} description={onboardingPage.description} />
        {children}
      </ChildrenWrapper>
    </Layout>
  );
};

export default LayoutWrapper;
