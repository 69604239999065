import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';
import styled from 'styled-components';
import { useMediaQuery } from '@kvika/hooks';
import { Grid, MediaQuery } from '@kvika/theme';
import StepperNavigation from '../Navigation/StepperNavigation';
import { useSliceContext } from '../../SliceZone';
import { NavigationLinkType } from '../../../types/Types';
import { StyledContent, StyledNavigation } from './ApplicationReceivedSliceStyles';
import { Maybe, Scalars } from '../../../types/PrismicTypes';

type Props = {
  title: string;
  description: Maybe<Scalars['Json']>;
};

const StyledH3 = styled.h3`
  margin-bottom: ${Grid['16px']};
`;

const ApplicationReceivedSlice = ({ title, description }: Props) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);
  const { goToPreviousPage, goToNextPage, navigationPrimary } = useSliceContext();

  const onNavigateForward = () => {
    setIsLoadingSubmit(true);
    goToNextPage();
  };

  const isMobile = useMediaQuery(`(max-width: ${MediaQuery.Mobile})`);

  return (
    <div>
      <StyledContent>
        <StyledH3>{title}</StyledH3>
        <PrismicRichText field={description} />
      </StyledContent>
      <StyledNavigation>
        {navigationPrimary && (
          <StepperNavigation
            onNavigateForward={onNavigateForward}
            onNavigateBackwards={goToPreviousPage}
            navigation={navigationPrimary}
            forwardDisabled={false}
            isLoadingSubmit={isLoadingSubmit}
            backButtonLinkType={NavigationLinkType.HomeDarkGrey}
            columnDirection={isMobile}
          />
        )}
      </StyledNavigation>
    </div>
  );
};

export default ApplicationReceivedSlice;
