import styled from 'styled-components';
import { Colors, Grid } from '@kvika/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
`;

export const StyledHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  margin: 20px 0;
`;

export const StyledInformationText = styled.div`
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: ${Colors.gray400};
  width: 320px;
`;

export const StyledFormRow = styled.div`
  margin-bottom: 20px;
`;

export const StyledLink = styled.div`
  margin-top: ${Grid['64px']};
`;
