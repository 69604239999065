import { Colors, Grid } from '@kvika/theme';
import styled, { css } from 'styled-components';

export const StyledLang = styled.div`
  position: relative;
`;

export const StyledSelectedLangContainer = styled.button`
  display: flex;
  align-items: center;
`;

export const StyledDefaultButton = styled.button`
  color: inherit;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  line-height: 20px;
`;

const getDefaultButtonStyles = () => {
  return css`
    font-size: 100%;
    color: inherit;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    line-height: 20px;
  `;
};

export const StyledSelectedLang = styled.span`
  margin-right: ${Grid['8px']};
  font-weight: bold;
  font-size: ${Grid['16px']};
  color: ${Colors.gold600};
`;

export const StyledLangPicker = styled.div`
  position: absolute;
  margin-top: 35px;
  border: 1px solid ${Colors.gray400};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  border-radius: 2px;
  background-color: ${Colors.white};
  font-size: ${Grid['16px']};
`;

export const StyledLangDivider = styled.div`
  border-bottom: 1px solid ${Colors.gray400};
  width: 100%;
`;

export const StyledLangItem = styled.button`
  margin: 10px 20px;
  font-weight: bold;
  font-size: ${Grid['16px']};
  ${getDefaultButtonStyles()}
`;

export const StyledArrow = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
  top: -8px;
  right: 3px;
  background-color: white;
  border-top: 1px solid ${Colors.gray400};
  border-left: 1px solid ${Colors.gray400};

  @media (min-width: 350px) {
    right: unset;
  }
`;
