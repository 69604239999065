import { DocumentType, InvestorTypeEnum } from '@kvika/api-types';
import {
  Maybe,
  ProgressStepper,
  Step,
  Banner,
  ErrorStrings,
  OnboardingPageBodyProfessional_Investors_UploadsFields,
  OnboardingPageBodyCategorizationFields,
} from '../../types/PrismicTypes';
import {
  ProgressStepperContent,
  StepContent,
  BannerContent,
  StringsDataMap,
  InvestorUploadContent,
  Category,
} from '../../types/Types';

const PRISMIC_REF_API_URL = `https://kvika-onboarding.prismic.io/api/v2`;
const PRISMIC_GRAPHQL_API_URL = `https://kvika-onboarding.prismic.io/graphql`;
const PRISMIC_API_LOCALE = 'is';
const PRISMIC_API_DEFAULT_LOCALE = 'is';

export const prismicConstants = {
  refApiUrl: PRISMIC_REF_API_URL,
  gqlApiUrl: PRISMIC_GRAPHQL_API_URL,
  apiLocale: PRISMIC_API_LOCALE,
  apiDefaultLocale: PRISMIC_API_DEFAULT_LOCALE,
};

export interface Edge<E> {
  node: E;
}

export interface Connection<E> {
  edges: Maybe<Array<Maybe<Edge<E>>>>;
}

/**
 * Gets a list of nodes of the type E from the connection and edges,
 * returns an empty array if nothing is found
 * @param connection
 * @returns an array of nodes
 */
export const getNodesFromConnection = <E, T extends Connection<E>>(connection: Maybe<T>): Array<E> => {
  if (connection) {
    if (connection.edges) {
      const edges = connection.edges.filter((edge) => edge && 'node' in edge) as Array<Edge<E>>;
      const nodes = edges.map((edge) => edge.node);
      return nodes;
    }
  }
  return [];
};

/**
 * Returns a value from a maybe with a default fallback
 * @param value
 * @param defaultValue
 * @returns A value from a maybe
 */
export const valueOrDefault = <T>(value: Maybe<T>, defaultValue: T): T => {
  return value || defaultValue;
};

export const getProgressStepperContent = (progressStepper: ProgressStepper): ProgressStepperContent => {
  const steps = progressStepper.steps
    ? progressStepper.steps?.map((step) => {
        const innerStep = step.step as Step;
        return {
          number: innerStep.number ?? 0,
          title: innerStep.title ?? '',
        };
      })
    : [];

  return {
    steps,
  };
};

export const getCurrentStepContent = (currentStep: Step): StepContent => {
  return {
    number: currentStep.number ?? 0,
    title: currentStep.title ?? '',
  };
};

export const getBanner = (banner: Banner): BannerContent => {
  return {
    onBehalf: banner.on_behalf ?? '',
    switch: banner.switch ?? '',
    loggedInAs: banner.logged_in_as ?? '',
  };
};

export const getStrings = (errorStringPages: ErrorStrings[]): StringsDataMap => {
  const result: StringsDataMap = {};

  errorStringPages.forEach((page) => {
    const { errors } = page;
    errors?.forEach((error) => {
      if (!result[page._meta.lang]) {
        result[page._meta.lang] = {};
      }
      if (error.key && error.message) {
        result[page._meta.lang][error.key.toUpperCase()] = error.message as string;
      }
    });
  });

  return result;
};

export const getInvestorUploadContent = (
  fields: OnboardingPageBodyProfessional_Investors_UploadsFields[]
): InvestorUploadContent[] => {
  return fields.map((field) => ({
    buttonText: field.button_text ?? '',
    radioButtonCaption: field.radio_button_caption ?? '',
    radioButtonDescription: field.radio_button_description,
    documentType: field.document_type as DocumentType,
    key: field.key ?? '',
    uploadDescription: field.upload_description,
    uploadTitle: field.upload_title ?? '',
    fileUploadRequired: field.file_upload_required ?? false,
    category: field.category ?? '',
  }));
};

export const getCategoriesContent = (fields: OnboardingPageBodyCategorizationFields[]): Category[] => {
  return fields.map((field) => ({
    label: field.category_name ?? '',
    description: field.category_description ?? '',
    key: field.type as InvestorTypeEnum,
  }));
};
