import { ApiError } from '@kvika/api-client';
import { ServerErrors } from '../types/Types';

export const isNotFoundError = (error: ApiError) => {
  const { response } = error;
  if (response && response.status === 404) {
    const { data } = response;
    return data && data.detail && data.detail === ServerErrors.NotFound;
  }
  return false;
};
