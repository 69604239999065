const MAP: Record<string, number> = {
  kvika_eldgja: 11100, // Kvika - Eldgjá
  jupiter_eignalI: 9050, // Kvika Eignaleið I - Skuldabréfasafn
  jupiter_eignalII: 9051, // Kvika Eignaleið II - Varfærið safn
  jupiter_eignalIII: 9052, // Kvika Eignaleið III - Blandað safn
  jupiter_eignalIV: 9674, // Kvika Eignaleið IV - Vaxtarsafn
  jupiter_eignalV: 9053, // Kvika Eignaleið V - Hlutabréfasafn
  jupiter_rksvbs: 8223, // Kvika - Ríkisverðbréf stutt
  jupiter_rksskbr: 7847, // Kvika - Ríkisverðbréf
  gamma_index: 8166, // KVIKA - ríkisskuldabréfavísitala
  jupiter_hlutabrefavisitala: 9903, // Kvika - Hlutabréfavísitala
  jupiter_innlhltbr: 8735, // Kvika - Innlend hlutabréf
  kvika_stuttsk: 11223, // Kvika - Stutt skuldabréf
  gamma_covered: 9517, // Kvika - Sértryggð skuldabréf
  jupiter_innlend_skbr: 8964, // Kvika - Innlend skuldabréf
  jupiter_lausafj: 8925, // Kvika - Lausafjársjóður
};

export const mapPreselectedFund = (fundName: string): number | null => {
  if (fundName) {
    return MAP[fundName];
  }
  return null;
};
